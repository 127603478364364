<div class="modal-header">
    <button
        type="button"
        class="close"
        aria-hidden="true"
        (click)="onCancel()">
        &times;
    </button>
    <h2 class="modal-title">Send Delivery Summary to Intake</h2>
</div>
<div class="modal-body">Please confirm that you would like to send the delivery summary to your intake.</div>
<div class="modal-footer">
    <button
        type="button"
        class="btn btn-default"
        (click)="onCancel()"
        [disabled]="isSubmitting">
        Cancel
    </button>
    <button
        type="submit"
        class="btn btn-primary"
        (click)="onConfirm()"
        [disabled]="isSubmitting">
        <span
            *ngIf="isSubmitting"
            class="spinner-border spinner-border-sm"></span>
        Confirm
    </button>
</div>
