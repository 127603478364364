import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom, Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class MessagesService {
    public deliverySummarySendToIntake = (id: string): Promise<void> => firstValueFrom(this.deliverySummarySendToIntake$(id));

    constructor(private httpClient: HttpClient) {}

    private deliverySummarySendToIntake$ = (id: string): Observable<void> =>
        this.httpClient.post<void>(`/api/messages/${id}/deliverysummary/sendtointake`, null);
}
